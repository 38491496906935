import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from './message.service';

@Injectable({ providedIn: 'root' })
export class ApiService {

  constructor(private messageService: MessageService,
              private httpClient: HttpClient,
              private translate: TranslateService) { }

  _GetRequestBody() {
    const obj: any = {};
    obj.database = environment.database;
    obj.userid = localStorage.getItem('userid');
    obj.fiori_token = localStorage.getItem(environment.authTokenKey);
    obj.lang = this.translate.currentLang;
    return obj;
  }

  getTasks() {
    const body = this._GetRequestBody();
    return this.httpClient.post(environment.apiUrl +
      '/TransportesAB/bTransports/APPS/DriversTasks/Services/Service.xsjs?cmd=Get_MasterData',
      body
    );
  }

  getTask(id) {
    const body = this._GetRequestBody();
    body.DocEntry = [id];
    return this.httpClient.post(environment.apiUrl +
      '/TransportesAB/bTransports/APPS/DriversTasks/Services/Service.xsjs?cmd=Get_Details',
      body
    );
  }

  getTaskDetails(id,type) {
    const body = this._GetRequestBody();
    body.DocEntry = [id];
    body.Type = [type];
    return this.httpClient.post(environment.apiUrl +
      '/TransportesAB/bTransports/APPS/DriversTasks/Services/Service.xsjs?cmd=Get_DetailExpDest',
      body
    );
  }


}
