<div class="bg-pattern">
  <div class="main-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <div class="signin-content">
      <div class="title-container">
        <img slot="logo" src="/assets/images/sap-logo-svg.svg"/>
        <ui5-title level="H3" class="title">{{'login.welcome' | translate}}</ui5-title>
      </div>
      <form [formGroup]="loginForm">
        <div class="flex-column margin">
          <ui5-label class="block" for="userName" required>{{'login.username' | translate}}</ui5-label>
          <ui5-input #username id="username" formControlName="username"
                     placeholder="User" origami></ui5-input>
        </div>
        <div class="flex-column margin">
          <ui5-label class="block" for="password" required>{{'login.password' | translate}}</ui5-label>
          <ui5-input #password id="password" formControlName="password"
                     type="Password" placeholder="password" origami></ui5-input>
        </div>
        <div class="flex-column margin">
          <ui5-checkbox style="margin-left: -10px;" formControlName="remember"
                        (change)="loginForm.controls['remember'].setValue($event['target']['checked'])"
                        [checked]="loginForm.controls['remember']['value']"
                        ngDefaultControl text="{{'login.rememberme' | translate}}" oragami></ui5-checkbox>

          <!--<ui5-button class="barcode-login-btn" icon="bar-code" design="Transparent" (click)="fingerprintSignin()"
                      aria-labelledby="lblBarCode" ></ui5-button>-->
          <ui5-button class="fingerprint-login-btn" icon="" design="Transparent" (click)="fingerprintSignin()"
                      aria-labelledby="lblBarCode" ></ui5-button>
        </div>
        <ui5-button design="Emphasized" (click)="onSubmit(false)">{{'login.login' | translate}}</ui5-button>
        <!--<div class="fingerprint-auth" *ngIf="webAuthnAvailable">
          <span class="or" fxLayoutAlign="center start">OR</span>
          <ui5-button design="Emphasized" (click)="signup()"><mat-icon>fingerprint</mat-icon></ui5-button>
          <ui5-button design="Emphasized" (click)="webAuthSignin()"><mat-icon>fingerprint signin</mat-icon></ui5-button>
        </div>-->
        <div class="footer-logo">
          <span class="newoxygen" >Developed by
            <a href="https://newoxygen.com" target="_blank" class="link">
              <span class="new">new</span>
              <span class="oxygen">oxygen</span>
            </a>
          </span>
        </div>
      </form>
    </div>
  </div>
  <ui5-dialog #dialogLogin id="dialogLogin" header-text="Login">
    <div class="dialog-content">
      <span>Existe um Login efectuado noutro dispositivo, deseja continuar?</span>
    </div>
    <div class="dialog-footer">
      <ui5-button class="margin-btn" design="Transparent" (click)="closeDialog()">{{'general.cancel' | translate}}</ui5-button>
      <ui5-button design="Emphasized" (click)="confirmLogin()">OK</ui5-button>
    </div>
  </ui5-dialog>
</div>
