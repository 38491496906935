<ui5-page id="page" background-design="List">
  <header class="detail-page-header">
  <div class="detail-page-header-bar">
    <ui5-title>Taskboard</ui5-title>

  </div>
  </header>
  <div class="details-page">
    <div class="col-sm-12">
      <!-- Alert card start -->
      <div>
        <!--<div class="sub-title">Default Alert</div>-->
        <!--<button class="btn btn-primary btn-icon waves-light btn-add" (click)="openMyModal('effect-9')" mdbRippleRadius>-->
        <!--<button class="btn btn-primary btn-icon waves-light btn-add" >
          <i class="icofont icofont-plus"></i>
        </button>-->
        <div class="row">
          <div class="col-sm-12 col-md-12 col-xl-12">
            <div class="taskboard page-height">

              <div class="taskboard-wrapper">
                <div class="taskboard-list">
                  <h4 class="taskboard-header">Backlog</h4>
                  <div class="taskboard-cards" id="5ac21d4dab4b1375354b46b6" [dragula]='"tasklist"' [dragulaModel]='rows_backlog'>
                    <!--<div *ngFor="let row of rows_backlog" [attr.class]="'taskboard-task bg-light task-status-'+row.type.name" [attr.id]="row.id" (click)='onclick(row,modalLarge)'>
                      <div class="taskboard-task-title">{{row.title}}</div>
                      <small class="card-text text-muted">{{row.content}}</small>
                    </div>-->
                    <div class="taskboard-task bg-light" (click)="openTask()">
                      <div class="taskboard-task-title">Travel checklist</div>
                      <small class="card-text text-muted">Curabitur blandit tempus porttitor.</small>
                    </div>
                    <div class="taskboard-task bg-light task-status-success" (click)="openTask()">
                      <div class="taskboard-task-title">Budget review</div>
                      <small class="card-text text-muted">Cras justo odio, dapibus ac facilisis in, egestas eget quam. Integer posuere erat a ante venenatis dapibus posuere velit aliquet.</small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="taskboard-wrapper">
                <div class="taskboard-list">
                  <h4 class="taskboard-header">To Do</h4>
                  <div class="taskboard-cards" id="5ac21d56ab4b1375354b46b7" [dragula]='"tasklist"' [dragulaModel]='rows_todo'>
                    <!--<div *ngFor="let row of rows_todo" [attr.class]="'taskboard-task bg-light task-status-'+row.type.name" [attr.id]="row.id" (click)='onclick(row,modalLarge)'>
                      <div class="taskboard-task-title">{{row.title}}</div>
                      <small class="card-text text-muted">{{row.content}}</small>
                    </div>
                    <div class="taskboard-cards" [dragula]='"task-group"'>-->
                      <div class="taskboard-task bg-light" (click)="openTask()">
                        <div class="taskboard-task-title">QA Testing</div>
                        <small class="card-text text-muted">Etiam porta sem malesuada magna mollis euismod.</small>
                      </div>
                      <div class="taskboard-task bg-light task-status-danger" (click)="openTask()">
                        <div class="taskboard-task-title">Layout design</div>
                        <small class="card-text text-muted">Sed posuere consectetur est at lobortis. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</small>
                      </div>
                      <div class="taskboard-task bg-light task-status-info" (click)="openTask()">
                        <div class="taskboard-task-title">Fix navigation menu</div>
                        <small class="card-text text-muted">Donec sed odio dui.</small>
                      </div>
                      <div class="taskboard-task bg-light" (click)="openTask()">
                        <div class="taskboard-task-title">Update bootstrap 4</div>
                        <small class="card-text text-muted">Aenean lacinia bibendum nulla sed consectetur.</small>
                      </div>
                      <div class="taskboard-task bg-light" (click)="openTask()">
                        <div class="taskboard-task-title">Run build tools</div>
                        <small class="card-text text-muted">Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.</small>
                      </div>
                      <div class="taskboard-task bg-light task-status-success" (click)="openTask()">
                        <div class="taskboard-task-title">List article ideas</div>
                        <small class="card-text text-muted">Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</small>
                      </div>
                      <div class="taskboard-task bg-light" (click)="openTask()">
                        <div class="taskboard-task-title">Reactjs fixes</div>
                        <small class="card-text text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</small>
                      </div>

                    <!--</div>-->
                  </div>
                </div>
              </div>

              <div class="taskboard-wrapper">
                <div class="taskboard-list">
                  <h4 class="taskboard-header">In Process</h4>
                  <div class="taskboard-cards" id="5ac21d62ab4b1375354b46b8" [dragula]='"tasklist"' [dragulaModel]='rows_inprocess'>
                    <!--<div *ngFor="let row of rows_inprocess" [attr.class]="'taskboard-task bg-light task-status-'+row.type.name" [attr.id]="row.id" (click)='onclick(row,modalLarge)'>
                      <div class="taskboard-task-title">{{row.title}}</div>
                      <small class="card-text text-muted">{{row.content}}</small>
                    </div>-->
                    <!--<div class="taskboard-cards" [dragula]='"task-group"'>-->
                      <div class="taskboard-task bg-light" (click)="openTask()">
                        <div class="taskboard-task-title">QOS Assessment</div>
                        <small class="card-text text-muted">Maecenas sed diam eget risus varius blandit sit amet non magna.</small>
                      </div>
                      <div class="taskboard-task bg-light task-status-warning" (click)="openTask()">
                        <div class="taskboard-task-title">Schedule new tasks</div>
                        <small class="card-text text-muted">Sed posuere consectetur est at lobortis.</small>
                      </div>
                      <div class="taskboard-task bg-light" (click)="openTask()">
                        <div class="taskboard-task-title">Add dashboard variants</div>
                        <small class="card-text text-muted">Nulla vitae elit libero, a pharetra augue.</small>
                      </div>
                      <div class="taskboard-task bg-light" (click)="openTask()">
                        <div class="taskboard-task-title">Extended color scheme support</div>
                        <small class="card-text text-muted">Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</small>
                      </div>
                      <div class="taskboard-task bg-light task-status-info" (click)="openTask()">
                        <div class="taskboard-task-title">Merge unit tests</div>
                        <small class="card-text text-muted">Maecenas sed diam eget risus varius blandit sit amet non magna.</small>
                      </div>
                      <div class="taskboard-task bg-light" (click)="openTask()">
                        <div class="taskboard-task-title">Test final version</div>
                        <small class="card-text text-muted">Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</small>
                      </div>
                    <!--</div>-->
                  </div>
                </div>
              </div>

              <div class="taskboard-wrapper">
                <div class="taskboard-list">
                  <h4 class="taskboard-header">In Review</h4>
                  <div class="taskboard-cards" id="5ac21d6bab4b1375354b46b9" [dragula]='"tasklist"' [dragulaModel]='rows_inreview'>
                    <!--<div *ngFor="let row of rows_inreview" [attr.class]="'taskboard-task bg-light task-status-'+row.type.name" [attr.id]="row.id" (click)='onclick(row,modalLarge)'>
                      <div class="taskboard-task-title">{{row.title}}</div>
                      <small class="card-text text-muted">{{row.content}}</small>
                    </div>
                  </div>-->
                  <!--<div class="taskboard-cards" [dragula]='"task-group"'>-->
                    <div class="taskboard-task bg-light" (click)="openTask()">
                      <div class="taskboard-task-title">Integrate Angular 4</div>
                      <small class="card-text text-muted">Nulla vitae elit libero, a pharetra augue.</small>
                    </div>
                    <div class="taskboard-task bg-light" (click)="openTask()">
                      <div class="taskboard-task-title">Additional fields</div>
                      <small class="card-text text-muted">Donec id elit non mi porta gravida at eget metus.</small>
                    </div>
                    <div class="taskboard-task bg-light task-status-danger" (click)="openTask()">
                      <div class="taskboard-task-title">Draggable task board</div>
                      <small class="card-text text-muted">Sed posuere consectetur est at lobortis.</small>
                    </div>
                    <div class="taskboard-task bg-light task-status-danger" (click)="openTask()">
                      <div class="taskboard-task-title">Setup CI server</div>
                      <small class="card-text text-muted">Maecenas faucibus mollis interdum.</small>
                    </div>
                    <div class="taskboard-task bg-light" (click)="openTask()">
                      <div class="taskboard-task-title">Assign new tasks</div>
                      <small class="card-text text-muted">Nullam quis risus eget urna mollis ornare vel eu leo.</small>
                    </div>
                    <div class="taskboard-task bg-light" (click)="openTask()">
                      <div class="taskboard-task-title">Contact administrator</div>
                      <small class="card-text text-muted">Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.</small>
                    </div>
                    <div class="taskboard-task bg-light" (click)="openTask()">
                      <div class="taskboard-task-title">Commit changes</div>
                      <small class="card-text text-muted">Aenean lacinia bibendum nulla sed consectetur.</small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="taskboard-wrapper">
                <div class="taskboard-list">
                  <h4 class="taskboard-header">Done</h4>
                  <div class="taskboard-cards" id="5ac21d70ab4b1375354b46ba" [dragula]='"tasklist"' [dragulaModel]='rows_done'>
                    <!--<div *ngFor="let row of rows_done" [attr.class]="'taskboard-task bg-light task-status-'+row.type.name" [attr.id]="row.id" (click)='onclick(row,modalLarge)'>
                      <div class="taskboard-task-title">{{row.title}}</div>
                      <small class="card-text text-muted">{{row.content}}</small>
                    </div>
                  </div>-->
                  <!--<div class="taskboard-cards" [dragula]='"task-group"'>-->
                    <div class="taskboard-task bg-light task-status-info" (click)="openTask()">
                      <div class="taskboard-task-title">Store new files</div>
                      <small class="card-text text-muted">Sed posuere consectetur est at lobortis.</small>
                    </div>
                    <div class="taskboard-task bg-light" (click)="openTask()">
                      <div class="taskboard-task-title">Build landing page</div>
                      <small class="card-text text-muted">Maecenas sed diam eget risus varius blandit sit amet non magna.</small>
                    </div>
                    <div class="taskboard-task bg-light" (click)="openTask()">
                      <div class="taskboard-task-title">Setup basic layout</div>
                      <small class="card-text text-muted">Vestibulum id ligula porta felis euismod semper.</small>
                    </div>
                    <div class="taskboard-task bg-light" (click)="openTask()">
                      <div class="taskboard-task-title">Graphical fixes</div>
                      <small class="card-text text-muted">Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</small>
                    </div>
                    <div class="taskboard-task bg-light task-status-warning" (click)="openTask()">
                      <div class="taskboard-task-title">Email alerts</div>
                      <small class="card-text text-muted">Donec sed odio dui.</small>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>

  <ui5-dialog #dialogTask header-text="Task" >
    <div [formGroup]="formDialog" class="dialog-content">
      <div class="margin date-edit-fields">
        <ui5-label class="block" required>Title:</ui5-label>
        <ui5-input #title formControlName="title" class="full-width" origami></ui5-input>
      </div>
      <div class="margin date-edit-fields">
        <ui5-label class="block" required>Detail:</ui5-label>
        <ui5-textarea #detail formControlName="detail" class="full-width" origami></ui5-textarea>
      </div>
    </div>
    <div class="dialog-footer">
      <ui5-button class="margin-btn" design="Transparent" (click)="closeDialog()">Back</ui5-button>
      <ui5-button design="Emphasized" (click)="saveDialog()">Submit</ui5-button>
    </div>
  </ui5-dialog>
</ui5-page>
