<ui5-page id="page" class="page page-padding" background-design="Solid" floating-footer show-footer>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px">
    <div fxFlex="auto">
      <ui5-card heading="David Willams" subheading="{{'dashboard.salesmanager' | translate}}" class="small">
        <ui5-card-header slot="header" title-text="David Willams" subtitleText="{{'dashboard.salesmanager' | translate}}">
          <img src="/assets/images/man_avatar_1.png" slot="avatar" />
        </ui5-card-header>

        <ui5-list separators="Inner" >
          <ui5-li icon="competitor" icon-end>{{'dashboard.personaldevelopment' | translate}}</ui5-li>
          <ui5-li icon="wallet" icon-end>{{'dashboard.finance' | translate}}</ui5-li>
          <ui5-li icon="collaborate" icon-end>{{'dashboard.communicationsskills' | translate}}</ui5-li>
        </ui5-list>
      </ui5-card>
    </div>
    <div fxFlex="auto">
      <ui5-card heading="{{'dashboard.projectcloud' | translate}}" subheading="{{'dashboard.revenueperproduct' | translate}} | EUR" status="3 of 3" class="small">
        <ui5-card-header slot="header" titleText="{{'dashboard.projectcloud' | translate}}" subtitleText="{{'dashboard.revenueperproduct' | translate}} | EUR" status="3 of 3">
        </ui5-card-header>
        <ui5-list separators="None" class="content-padding">
          <ui5-li-custom>
            <div class="item">
              <div class="item-content-begin">
                <ui5-title level="H5" class="item-content-begin-title">Avantel</ui5-title>
                <ui5-label>ID234522566-D44</ui5-label>
              </div>
              <div class="item-content-end">
                <span class="status-success">27.25K EUR</span>
              </div>
            </div>
          </ui5-li-custom>
          <ui5-li-custom>
            <div class="item">
              <div class="item-content-begin">
                <ui5-title level="H5" class="item-content-begin-title">Avantel</ui5-title>
                <ui5-label>ID234522566-D44</ui5-label>
              </div>
              <div class="item-content-end">
                <span class="status-warning">27.25K EUR</span>
              </div>
            </div>
          </ui5-li-custom>
          <ui5-li-custom>
            <div class="item">
              <div class="item-content-begin">
                <ui5-title level="H5" class="item-content-begin-title">Avantel</ui5-title>
                <ui5-label>ID234522566-D44</ui5-label>
              </div>
              <div class="item-content-end">
                <span class="status-error">27.25K EUR</span>
              </div>
            </div>
          </ui5-li-custom>
        </ui5-list>
      </ui5-card>
    </div>
    <div fxFlex="auto">
      <ui5-card heading="Dona Maria Moore" subheading="{{'dashboard.salesexecutive' | translate}}" class="small">
        <!--<img src="/assets/images/woman_avatar_5.png" slot="avatar" />-->
        <ui5-card-header slot="header" title-text="Dona Maria Moore" subtitleText="{{'dashboard.salesexecutive' | translate}}">
          <img src="/assets/images/woman_avatar_5.png" slot="avatar" />
        </ui5-card-header>

        <div class="content content-padding">
          <ui5-title level="H5" style="padding-bottom: 1rem;">{{'dashboard.contactdetails' | translate}}</ui5-title>

          <div class="content-group">
            <ui5-label>{{'dashboard.companyname' | translate}}</ui5-label>
            <ui5-title level="H6">Company A</ui5-title>
          </div>
          <div class="content-group">
            <ui5-label>{{'dashboard.address' | translate}}</ui5-label>
            <ui5-title level="H6">481 West Street, Anytown 45066, USA</ui5-title>
          </div>
          <div class="content-group">
            <ui5-label>Website</ui5-label>
            <ui5-link target="_blank">www.company_a.example.com</ui5-link>
          </div>
        </div>
      </ui5-card>
    </div>
  </div>

  <ui5-card  heading="{{'dashboard.purchaseorders' | translate}}" subheading="{{'dashboard.time' | translate}}"
             status="online" class="medium">
    <ui5-card-header slot="header"
                     titleText="{{'dashboard.purchaseorders' | translate}}"
                     subtitleText="{{'dashboard.time' | translate}}" status="online">
    </ui5-card-header>
    <div #ContainerRef style="margin: 30px;">
      <ngx-charts-line-chart
        [view]="[ContainerRef.offsetWidth, 500]"
        [scheme]="colorScheme"
        [legend]="legend"
        [legendTitle]="legendTitle"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [xAxisTickFormatting]="xAxisTickFormatting"
        [timeline]="timeline"
        [results]="multi"
        (select)="onSelect($event)"
        (activate)="onActivate($event)"
        (deactivate)="onDeactivate($event)">
      </ngx-charts-line-chart>
    </div>
  </ui5-card>
</ui5-page>


