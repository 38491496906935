<ui5-page id="page" class="page" background-design="Solid">
  <header class="detail-page-header">
    <div class="detail-page-header-bar">
      <ui5-title>{{pos?.name | uppercase}} ID: {{pos?.id}}</ui5-title>
      <!--<ui5-button
        design="Transparent"
        icon="sap-icon://action"
        class="action-button"></ui5-button>-->
      <ui5-badge *ngIf="pos?.status != 'Active' && pos?.status != 'Ativo'" color-scheme="2">{{pos?.status}}</ui5-badge>
      <ui5-badge *ngIf="pos?.status == 'Active' || pos?.status == 'Ativo'" color-scheme="8">{{pos?.status}}</ui5-badge>
    </div>
  </header>
  <div class="details-page">
    <div [formGroup]="form" class="content">
      <ui5-title level="H3">{{'pos.exportsaft' | translate}}</ui5-title>
      <div class="ui5-content-border"><span></span></div>
      <div class="flex-column margin">
        <ui5-label class="block" for="dateStart" required>{{'pos.datestart' | translate}}:</ui5-label>
        <ui5-date-picker #DatePickerStart formControlName="dateStart" format-pattern="dd/MM/yyyy"
                        value-state="" origami></ui5-date-picker>
      </div>
      <div class="flex-column margin">
        <ui5-label  class="block" for="dateEnd" required>{{'pos.dateend' | translate}}:</ui5-label>
        <ui5-date-picker #DatePickerEnd formControlName="dateEnd" format-pattern="dd/MM/yyyy"
                        value-state="" origami></ui5-date-picker>
      </div>
    </div>
    <div class="footer">
      <ui5-button design="Transparent" class="margin-btn" (click)="goBack()">{{'general.back' | translate}}</ui5-button>
      <ui5-button design="Emphasized" class="margin-btn" (click)="exportSAFT()">{{'pos.export' | translate}}</ui5-button>
    </div>
  </div>
</ui5-page>
