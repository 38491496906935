import { Pos } from './models/pos';

export const POS: Pos[] = [
  { id: 1, name: 'POS 1', location: 'Leiria', store: 'Store 1', status: 'Active' },
  { id: 2, name: 'POS 2', location: 'Santarem', store: 'Store 1', status: 'Active' },
  { id: 3, name: 'POS 3', location: 'Obidos', store: 'Store 1', status: 'Active' },
  { id: 4, name: 'POS 4', location: 'Lisboa', store: 'Store 1', status: 'Active' },
  { id: 5, name: 'POS 5', location: 'Lisboa', store: 'Store 1', status: 'Active' },
  { id: 6, name: 'POS 6', location: 'Leiria', store: 'Store 1', status: 'Active' },
  { id: 7, name: 'POS 7', location: 'Santarem', store: 'Store 1', status: 'Active' },
  { id: 8, name: 'POS 8', location: 'Obidos', store: 'Store 1', status: 'Active' },
  { id: 9, name: 'POS 9', location: 'Lisboa', store: 'Store 1', status: 'Active' },
  { id: 10, name: 'POS 10', location: 'Lisboa', store: 'Store 1', status: 'Active' },
  { id: 11, name: 'POS 11', location: 'Leiria', store: 'Store 1', status: 'Active' },
  { id: 12, name: 'POS 12', location: 'Santarem', store: 'Store 1', status: 'Active' },
  { id: 13, name: 'POS 13', location: 'Obidos', store: 'Store 1', status: 'Active' },
  { id: 14, name: 'POS 14', location: 'Lisboa', store: 'Store 1', status: 'Active' },
  { id: 15, name: 'POS 15', location: 'Lisboa', store: 'Store 1', status: 'Active' },
  { id: 16, name: 'POS 16', location: 'Leiria', store: 'Store 1', status: 'Active' },
  { id: 17, name: 'POS 17', location: 'Santarem', store: 'Store 1', status: 'Active' },
  { id: 18, name: 'POS 18', location: 'Obidos', store: 'Store 1', status: 'Active' },
  { id: 19, name: 'POS 19', location: 'Lisboa', store: 'Store 1', status: 'Active' },
  { id: 20, name: 'POS 20', location: 'Lisboa', store: 'Store 1', status: 'Active' }
];
