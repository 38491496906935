export let multi = [
  {
    name: 'Portugal',
    series: [
      {
        value: 6681,
        name: '2016-09-16T15:09:06.808Z'
      },
      {
        value: 2239,
        name: '2016-09-20T01:30:24.582Z'
      },
      {
        value: 3605,
        name: '2016-09-14T06:49:03.745Z'
      },
      {
        value: 6037,
        name: '2016-09-13T21:10:15.687Z'
      },
      {
        value: 5086,
        name: '2016-09-15T05:54:10.604Z'
      }
    ]
  },
  {
    name: 'England',
    series: [
      {
        value: 5163,
        name: '2016-09-16T15:09:06.808Z'
      },
      {
        value: 5664,
        name: '2016-09-20T01:30:24.582Z'
      },
      {
        value: 2115,
        name: '2016-09-14T06:49:03.745Z'
      },
      {
        value: 4724,
        name: '2016-09-13T21:10:15.687Z'
      },
      {
        value: 4392,
        name: '2016-09-15T05:54:10.604Z'
      }
    ]
  },
  {
    name: 'Germany',
    series: [
      {
        value: 3132,
        name: '2016-09-16T15:09:06.808Z'
      },
      {
        value: 5102,
        name: '2016-09-20T01:30:24.582Z'
      },
      {
        value: 3691,
        name: '2016-09-14T06:49:03.745Z'
      },
      {
        value: 5864,
        name: '2016-09-13T21:10:15.687Z'
      },
      {
        value: 6664,
        name: '2016-09-15T05:54:10.604Z'
      }
    ]
  },
  {
    name: 'France',
    series: [
      {
        value: 4166,
        name: '2016-09-16T15:09:06.808Z'
      },
      {
        value: 6749,
        name: '2016-09-20T01:30:24.582Z'
      },
      {
        value: 3720,
        name: '2016-09-14T06:49:03.745Z'
      },
      {
        value: 4655,
        name: '2016-09-13T21:10:15.687Z'
      },
      {
        value: 6673,
        name: '2016-09-15T05:54:10.604Z'
      }
    ]
  },
  {
    name: 'Spain',
    series: [
      {
        value: 5554,
        name: '2016-09-16T15:09:06.808Z'
      },
      {
        value: 3438,
        name: '2016-09-20T01:30:24.582Z'
      },
      {
        value: 2975,
        name: '2016-09-14T06:49:03.745Z'
      },
      {
        value: 5562,
        name: '2016-09-13T21:10:15.687Z'
      },
      {
        value: 6643,
        name: '2016-09-15T05:54:10.604Z'
      }
    ]
  }
];
