import {Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { DatatableComponent , ColumnMode, SelectionType } from '@swimlane/ngx-datatable';

import { Pos } from '../../models/pos';
import { PosService } from '../../services/pos.service';
import { ApiService } from '../../services/api.service';
import { LoadingComponent } from '../../shared/dialogs/loading/loading.component';
import { NotificationComponent } from '../../shared/dialogs/notification/notification.component';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-pos',
  templateUrl: './pos.component.html',
  styleUrls: [ './pos.component.scss' ]
})
export class PosComponent implements OnInit, AfterViewInit {
  @ViewChild('searchInput') searchInputEl: ElementRef;
  @ViewChild('allRowsCheck') allRowsCheckEl: ElementRef;
  @ViewChild('downloadBtn') downloadBtnEl: ElementRef;
  @ViewChild('datePickerStart') dateStartEl: ElementRef;
  @ViewChild('datePickerEnd') dateEndEl: ElementRef;
  @ViewChild('dialogExport') dialogEl: ElementRef;
  @ViewChild('datatable') table: any;
  pos: Pos[] = [];
  formDialog: FormGroup;
  rows = [];
  selected = [];
  filteredData = [];
  columnsWithSearch : string[] = [];
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;

  constructor(private posService: PosService,
              private apiService: ApiService,
              private fb: FormBuilder,
              private breakpointObserver: BreakpointObserver,
              private loading: LoadingComponent,
              private notification: NotificationComponent,
              private translate: TranslateService) { }

  ngOnInit(): void {
    const lang = this.translate.currentLang;
    this.getPOSs();

    this.formDialog = this.fb.group({
      dateStart: ['', Validators.required],
      dateEnd: ['', Validators.required]
    });
  }

  ngAfterViewInit(): void {
    this.breakpointObserver.observe([
      '(max-width: 960px)'
    ]).subscribe(result => {
      if (result.matches) {
        this.table.rowDetail.expandAllRows();
      } else {
        this.table.rowDetail.collapseAllRows();
      }
    });
  }

  updateFilter(event){
    let filter = event.target.value.toLowerCase();
    // @ts-ignore
    this.rows = this.filteredData.filter(item => {
      for (let i = 0; i < this.columnsWithSearch.length; i++){
        var colValue = item[this.columnsWithSearch[i]] ;
        if (!filter || (!!colValue && colValue.toString().toLowerCase().indexOf(filter) !== -1)) {
          return true;
        }
      }
    });
    // TODO - whenever the filter changes, always go back to the first page
    //this.table.offset = 0;
  }

  onSelect({ selected }) {
    //console.log('Select Event', selected, this.selected);
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  onActivate(event) {
    //console.log('Activate Event', event);
    if(event.type == 'click') {
      //console.log(event.row);
      this.dialogEl.nativeElement.show();
    }
  }

  remove() {
    this.selected = [];
  }

  rerender(): void {
    this.searchInputEl.nativeElement.value = '';
    this.allRowsCheckEl.nativeElement.checked = false;
    this.table.sorts = [];
    this.getPOSs();
  }

  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }

  toggleAllRows() {
    const self = this;
    setTimeout(() => {
      if(window.innerWidth > 960) {
        console.log('collapse');
        self.table.rowDetail.collapseAllRows();
      } else {
        console.log('expand');
        self.table.rowDetail.expandAllRows();
      }
    },200);
  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  exportSelected(): void {
    if(this.selected.length >0) {
      this.dialogEl.nativeElement.show();
    } else {
      this.notification.show('Warning',this.translate.instant('pos.selectrows'));
    }
  }

  closeDialog() {
    const dateStart = this.dateStartEl.nativeElement;
    const dateEnd = this.dateEndEl.nativeElement;
    dateStart.setAttribute('value-state', '');
    dateEnd.setAttribute('value-state', '');
    this.dialogEl.nativeElement.close();
  }

  saveDialog() {
    const controls = this.formDialog.controls;
    const dateStart = this.dateStartEl.nativeElement;
    const dateEnd = this.dateEndEl.nativeElement;
    dateStart.setAttribute('value-state', '');
    dateEnd.setAttribute('value-state', '');
    /** check form */
    if (this.formDialog.invalid) {
      Object.keys(controls).forEach(controlName => {
        controls[controlName].markAsTouched()
        if (controls[controlName].invalid) {
          try {eval(controlName).setAttribute('value-state', 'Error')} catch (e) {}
        }
      });
      return;
    }
    const formData = {
      dateStart: controls['dateStart'].value,
      dateEnd: controls['dateEnd'].value
    };

    const downloadLink = document.createElement('a');
    downloadLink.href = '/assets/data/SAFT-Example.xml';
    downloadLink.download = 'SAFT-Example-'+formData.dateStart+'-'+formData.dateStart+'.xml';
    downloadLink.click();
    this.formDialog.reset();
    this.closeDialog();
  }

  getPOSs(): void {
    const self = this;
    self.loading.show();
    this.posService.getPOSs()
      .subscribe(data => {
        setTimeout( () => {
          self.loading.hide();
          self.rows = data['data'];
          self.filteredData = this.rows;
          self.columnsWithSearch = Object.keys(this.rows[0]);
          self.toggleAllRows();
        }, 200);
      });
  }

}
