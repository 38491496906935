<ui5-bar class="ui5-bar" design="Header" style="position: sticky;top:0;z-index:2;">
  <!--<ui5-button icon="home" title="Go home" design="Transparent" slot="startContent"></ui5-button>-->
  <ui5-label id="basic-label" slot="middleContent">Reactive Form</ui5-label>
  <ui5-button design="Emphasized" slot="endContent" (click)="submit()">{{'form.submit' | translate}}</ui5-button>
</ui5-bar>
<ui5-page id="page" class="page" background-design="Solid">
  <!--<header class="detail-page-header">
    <div class="detail-page-header-bar">
      <ui5-title>{{pos?.name | uppercase}} ID: {{pos?.id}}</ui5-title>
      <ui5-button
        design="Transparent"
        icon="sap-icon://action"
        class="action-button"></ui5-button>-->
      <!--<ui5-badge *ngIf="pos?.status != 'Active' && pos?.status != 'Ativo'" color-scheme="2">{{pos?.status}}</ui5-badge>
      <ui5-badge *ngIf="pos?.status == 'Active' || pos?.status == 'Ativo'" color-scheme="8">{{pos?.status}}</ui5-badge>
    </div>
  </header>-->
  <div class="details-page">
    <ui5-title level="H3">{{'form.supplier' | translate}}</ui5-title>
    <div class="ui5-content-border"><span></span></div>
      <div [formGroup]="formSupplier" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px">
        <div fxFlex="auto">
          <div class="ui5-form-border"></div>
          <ui5-title level="H5">{{'form.address' | translate}}</ui5-title>
          <div class="ui5-form-border"></div>
          <div class="flex-column margin">
            <ui5-label class="block" for="fullname" required>{{'form.name' | translate}}:</ui5-label>
            <ui5-input #name placeholder="{{'form.name' | translate}}" class="full-width"
                       formControlName="name" ngDefaultControl origami></ui5-input>
          </div>
          <div class="flex-column margin">
            <ui5-label class="block" for="street">{{'form.street' | translate}}:</ui5-label>
            <ui5-input placeholder="{{'form.street' | translate}}" class="full-width"
                       formControlName="street" ngDefaultControl  oragami></ui5-input>
          </div>
          <div class="flex-column margin">
            <ui5-label class="block" for="zipcode" >{{'form.zipcode' | translate}}:</ui5-label>
            <ui5-input placeholder="{{'form.zipcode' | translate}}" class="full-width"
                       formControlName="zipcode" ngDefaultControl oragami></ui5-input>
          </div>
          <div class="flex-column margin">
            <ui5-label class="block" for="country">{{'form.country' | translate}}:</ui5-label>
            <ui5-select placeholder="{{'form.country' | translate}}"
                        class="select" class="full-width"
                        (change)="formSupplier.controls['country'].setValue($event['detail']['selectedOption']['value'])"
                        formControlName="country" ngDefaultControl oragami>
              <ui5-option *ngFor="let ct of countries" [value]="ct.value"
                          [selected]="ct.value == formSupplier.controls['country']['value']">{{ct.value}}</ui5-option>
              <!--<ui5-option *ngFor="let ct of countries" [value]="ct"
                          [attr.selected]="ct == 'Portugal' ? true : null">{{ct}}</ui5-option>-->
            </ui5-select>
          </div>
          <div class="flex-column margin">
            <ui5-label class="block" for="web">{{'form.web' | translate}}:</ui5-label>
            <ui5-input placeholder="{{'form.web' | translate}}" class="full-width"
                       formControlName="web" ngDefaultControl oragami></ui5-input>
          </div>
        </div>

        <div fxFlex="auto">
          <div class="ui5-form-border"></div>
          <ui5-title level="H5">{{'form.contact' | translate}}</ui5-title>
          <div class="ui5-form-border"></div>
          <div class="flex-column margin">
            <ui5-label class="block" for="phone" required>{{'form.phone' | translate}}:</ui5-label>
            <ui5-input #phoneInput placeholder="{{'form.phone' | translate}}" class="full-width"
                       formControlName="phone" ngDefaultControl origami></ui5-input>
          </div>
          <div class="flex-column margin">
            <ui5-label class="block" for="email">Email:</ui5-label>
            <ui5-input placeholder="email" class="full-width"
                       formControlName="email" ngDefaultControl oragami></ui5-input>
          </div>
          <div class="flex-column margin">
            <ui5-label class="block" for="twitter">Twitter:</ui5-label>
            <ui5-input placeholder="Twitter" class="full-width"
                       formControlName="twitter" ngDefaultControl oragami></ui5-input>
          </div>
        </div>

        <div fxFlex="auto">
          <div class="ui5-form-border"></div>
          <ui5-title level="H5">{{'form.other' | translate}}</ui5-title>
          <div class="ui5-form-border"></div>
          <div class="flex-column margin">
            <ui5-label class="block" for="sms">SMS:</ui5-label>
            <ui5-input placeholder="SMS" class="full-width"
                       formControlName="sms" ngDefaultControl oragami></ui5-input>
          </div>
          <div class="flex-column margin">
            <ui5-label class="block" for="mobile">Mobile:</ui5-label>
            <ui5-input placeholder="Mobile" class="full-width"
                       formControlName="mobile" ngDefaultControl oragami></ui5-input>
          </div>
          <div class="flex-column margin">
            <ui5-label class="block" for="pager">Pager:</ui5-label>
            <ui5-input placeholder="Pager" class="full-width"
                       formControlName="pager" ngDefaultControl oragami></ui5-input>
          </div>
          <div class="flex-column margin">
            <ui5-label class="block" for="fax">Fax:</ui5-label>
            <ui5-input placeholder="fax" class="full-width"
                       formControlName="fax" ngDefaultControl oragami></ui5-input>
          </div>

          <div class="flex-column margin">
            <ui5-checkbox style="margin-left: -10px;" formControlName="active"
                          (change)="formSupplier.controls['active'].setValue($event['target']['checked'])"
                          [checked]="formSupplier.controls['active']['value']"
                          ngDefaultControl text="Active" oragami></ui5-checkbox>
          </div>
      </div>
    </div>
    <div class="footer">
      <ui5-messagestrip type="Information">{{formSupplier.value | json}}</ui5-messagestrip>
      <!--<ui5-button design="Transparent" class="margin-btn" (click)="goBack()">{{'general.back' | translate}}</ui5-button>
      <ui5-button design="Emphasized" class="margin-btn" (click)="exportSAFT()">{{'pos.export' | translate}}</ui5-button>-->
    </div>
  </div>
</ui5-page>
