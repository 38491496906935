<ui5-page id="page" background-design="List">
  <header class="detail-page-header">
    <div class="detail-page-header-bar">
      <ui5-title>{{'menu.history' | translate}}</ui5-title>
      <ui5-button
        design="Transparent"
        icon="synchronize"
        class="action-button"></ui5-button>
    </div>
  </header>
  <div class="details-page">
    <ui5-timeline>
      <ui5-timeline-item title-text="Weekly Sync - CP Design" subtitle-text="27.07.2017 (11:00 - 12:30)" icon="calendar">
        <div>MR SOF02 2.43</div>
      </ui5-timeline-item>
      <ui5-timeline-item title-text="Weekly Sync - CP Design" subtitle-text="27.07.2017 (11:00 - 12:30)" icon="calendar">
        <div>MR SOF02 2.43</div>
      </ui5-timeline-item>
      <ui5-timeline-item title-text="Video Converence Call - UI5" subtitle-text="31.01.2018 (12:00 - 13:00)" icon="calendar">
        <div>Online meeting</div>
      </ui5-timeline-item>
      <ui5-timeline-item title-text="Video Converence Call - UI5" subtitle-text="31.01.2018 (12:00 - 13:00)" icon="calendar">
        <div>Online meeting</div>
      </ui5-timeline-item>
      <ui5-timeline-item title-text="Video Converence Call - UI5" subtitle-text="31.01.2018 (12:00 - 13:00)" icon="calendar">
        <div>Online meeting</div>
      </ui5-timeline-item>
      <ui5-timeline-item title-text="Video Converence Call - UI5" subtitle-text="31.01.2018 (12:00 - 13:00)" icon="calendar">
        <div>Online meeting</div>
      </ui5-timeline-item>
    </ui5-timeline>
  </div>
</ui5-page>


