<ui5-page id="page" background-design="List">
  <header class="detail-page-header">
    <div class="detail-page-header-bar">
      <ui5-title>{{'pos.list' | translate}}</ui5-title>
      <ui5-button
        design="Transparent"
        icon="synchronize"
        class="action-button" (click)="rerender()"></ui5-button>
    </div>
  </header>
  <div class="details-page">
    <div class="toolbar">
      <div class="btns-container">
        <ui5-button #downloadBtn icon="download" (click)="exportSelected()" icon-end>Exp. SAFT</ui5-button>
      </div>
      <ui5-input #searchInput class="searchInput" (keyup)="updateFilter($event)" placeholder="{{'pos.search' | translate}}..." >
        <ui5-icon id="searchIcon" slot="icon" name="search"></ui5-icon>
      </ui5-input>
    </div>

    <ngx-datatable
      #datatable
      style="width: 100%"
      class="material"
      [rows]="rows"
      [columnMode]="ColumnMode.force"
      [headerHeight]="50"
      [footerHeight]="50"
      rowHeight="auto"
      [limit]="10"
      [selected]="selected"
      [selectionType]="SelectionType.checkbox"
      [messages]="{emptyMessage: 'pos.emptymessage' | translate,
        totalMessage: 'pos.totalmessage' | translate,
        selectedMessage: 'pos.selectedmessage' | translate}"
      (activate)="onActivate($event)"
      (select)="onSelect($event)">

      <!-- Row Detail Template -->
      <ngx-datatable-row-detail #myDetailRow (toggle)="onDetailToggle($event)">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
          <div class="mobile-row" [routerLink]="['/tasks', row['id']]">
            <div class="row-line-container">
              <ui5-label class="row-title">{{'pos.columns.location' | translate}}:</ui5-label>
              <span class="row-line">{{ row['location'] }}</span>
              <ui5-label class="row-title">{{'pos.columns.store' | translate}}:</ui5-label>
              <span class="row-line">{{ row['store'] }}</span>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-row-detail>

      <ngx-datatable-column
        [width]="50"
        [sortable]="false"
        [canAutoResize]="false"
        [draggable]="false"
        [resizeable]="false">
        <ng-template
          ngx-datatable-header-template
          let-value="value"
          let-allRowsSelected="allRowsSelected"
          let-selectFn="selectFn">
          <ui5-checkbox #allRowsCheck class="select-all pointer" [checked]="allRowsSelected"
                        (change)="selectFn(!allRowsSelected)"></ui5-checkbox>
        </ng-template>
        <ng-template
          ngx-datatable-cell-template
          let-value="value"
          let-isSelected="isSelected"
          let-onCheckboxChangeFn="onCheckboxChangeFn">
          <ui5-checkbox [checked]="isSelected" (click)="$event.stopPropagation()"
                        (change)="onCheckboxChangeFn($event)"></ui5-checkbox>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Name" [flexGrow]="3">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span class="pointer" (click)="sort()">{{'pos.columns.name' | translate}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <b>{{value}}</b>
          <span class="row-id">{{row['id']}}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Location" [flexGrow]="1">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span class="pointer mobile-hidden" (click)="sort()">{{'pos.columns.location' | translate}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span class="mobile-hidden">{{ value }}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Store" [flexGrow]="1">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span class="pointer mobile-hidden" (click)="sort()">{{'pos.columns.store' | translate}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span class="mobile-hidden">{{ value }}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Status" [minWidth]="70" [flexGrow]="1">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span class="pointer" (click)="sort()">{{'pos.columns.status' | translate}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <ui5-badge *ngIf="value != 'Active' && value != 'Ativo'" color-scheme="2">
            {{'pos.inactive' | translate}}
          </ui5-badge>
          <ui5-badge *ngIf="value == 'Active' || value == 'Ativo'" color-scheme="8">
            {{'pos.active' | translate}}
          </ui5-badge>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="" [sortable]="false" [maxWidth]="70" >
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <ui5-button class="nav-btn" icon="navigation-right-arrow" design="Transparent"
                      aria-labelledby="lblNavigationRightArrow"></ui5-button>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>

  <ui5-dialog #dialogExport header-text="Exportar SAFT" >
    <div [formGroup]="formDialog" class="dialog-content">
      <div class="margin date-edit-fields">
        <ui5-label class="block">{{'pos.datestart' | translate}}:</ui5-label>
        <ui5-date-picker #datePickerStart formControlName="dateStart" format-pattern="dd/MM/yyyy" origami></ui5-date-picker>
      </div>
      <div class="margin date-edit-fields">
        <ui5-label class="block">{{'pos.dateend' | translate}}:</ui5-label>
        <ui5-date-picker #datePickerEnd formControlName="dateEnd" format-pattern="dd/MM/yyyy" origami></ui5-date-picker>
      </div>
    </div>
    <div class="dialog-footer">
      <ui5-button class="margin-btn" design="Transparent" (click)="closeDialog()">{{'general.back' | translate}}</ui5-button>
      <ui5-button design="Emphasized" (click)="saveDialog()">{{'pos.export' | translate}}</ui5-button>
    </div>
  </ui5-dialog>
</ui5-page>
