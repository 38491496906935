import { Component, OnInit } from '@angular/core';
import { multi } from '../../mock-chart';
import { DatePipe } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [ './dashboard.component.scss' ]
})
export class DashboardComponent implements OnInit {

  multi: any[];
  view: any[] = [];

  // options
  legend = true;
  legendTitle = 'Legend'
  showLabels = true;
  animations = true;
  xAxis = true;
  yAxis = true;
  showYAxisLabel = true;
  showXAxisLabel = true;
  xAxisLabel = 'Date';
  yAxisLabel = 'Orders';
  timeline = true;

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  constructor(private translate: TranslateService) {
    Object.assign(this, { multi });
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((lang: any) => {
      this.xAxisLabel = this.translate.instant('dashboard.date');
      this.yAxisLabel = this.translate.instant('dashboard.orders');
      this.legendTitle = this.translate.instant('dashboard.legend');
    });
  }

  onSelect(data): void {
    //console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    //console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    //console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  xAxisTickFormatting(value): string {
    const date = new Date(value);
    const datePipe = new DatePipe('en-UK');
    return datePipe.transform(date, 'mediumDate');
  }

}
