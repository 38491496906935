import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

import {DragulaService} from 'ng2-dragula';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoadingComponent} from '../../shared/dialogs/loading/loading.component';
import {NotificationComponent} from '../../shared/dialogs/notification/notification.component';

@Component({
  selector: 'app-taskboard',
  templateUrl: './taskboard.component.html',
  styleUrls: ['./taskboard.component.scss']
})
export class TaskboardComponent implements OnInit {
  @ViewChild('title') titleEl: ElementRef;
  @ViewChild('detail') detailEl: ElementRef;
  @ViewChild('dialogTask') dialogEl: ElementRef;
  formDialog: FormGroup;
  rows_backlog = [];
  rows_todo = [];
  rows_inprocess = [];
  rows_inreview = [];
  rows_done = [];


  constructor(private fb: FormBuilder,
              private loading: LoadingComponent,
              private notification: NotificationComponent,
              private dragula: DragulaService) { }

  ngOnInit() {
    this.formDialog = this.fb.group({
      title: ['', Validators.required],
      detail: ['', Validators.required]
    });
    /*this.dragula
      .drag
      .subscribe(value => {
        //console.log(value);
        //console.log(value[1]);
        //this.msg = `Dragging the ${ value[1].innerText }!`;
        //alert('test');
      });

    this.dragula
      .drop
      .subscribe(value => {
        console.log(value);
        console.log(value[1].id);
        console.log(value[2].id);
        this.updateValue(value[1].id,value[2].id);
      });*/

  }

  openTask() {
    this.dialogEl.nativeElement.show();
  }

  closeDialog() {
    const dateStart = this.titleEl.nativeElement;
    const dateEnd = this.detailEl.nativeElement;
    dateStart.setAttribute('value-state', '');
    dateEnd.setAttribute('value-state', '');
    this.dialogEl.nativeElement.close();
  }

  saveDialog() {

    this.closeDialog();
  }

}


