<ui5-page id="page" background-design="List">
  <header class="detail-page-header">
  <div class="detail-page-header-bar">
    <ui5-title>TODO's</ui5-title>

  </div>
  </header>
  <div class="details-page">

    <app-user-input (addTodo)="handleAddTodo($event)"></app-user-input>
    <app-list [todos]="unDone"
              (editPressed)="editItem($event)"
              (deletedItem)="removeItem($event)"
              (changeSelection)="handleUndone($event)"></app-list>
    <ui5-panel [collapsed]="!this.done.length" header-text="Completed tasks">
      <app-list [todos]="done" (editPressed)="editItem($event)"
                (deletedItem)="removeItem($event)"
                (changeSelection)="handleDone($event)"></app-list>
    </ui5-panel>
    <aside>
      <ui5-dialog #dialog header-text="Editar Todo item" >
        <div class="dialog-content">
          <div class="edit-wrapper">
            <ui5-label>{{'todo.title' | translate}}:</ui5-label>
            <ui5-textarea #dialogInput class="title-textarea" maxlength="24" show-exceeded-text [value]="oItemToEdit.text"></ui5-textarea>
          </div>
          <div class="edit-wrapper date-edit-fields">
            <ui5-label>{{'todo.date' | translate}}:</ui5-label>
            <ui5-date-picker #dialogDatePicker format-pattern="dd/MM/yyyy" [value]="oItemToEdit.deadline"></ui5-date-picker>
          </div>
        </div>
        <div class="dialog-footer">
          <ui5-button design="Transparent" (click)="closeDialog()">{{'general.cancel' | translate}}</ui5-button>
          <ui5-button design="Emphasized" (click)="saveDialog()">{{'general.save' | translate}}</ui5-button>
        </div>
      </ui5-dialog>
    </aside>
  </div>
</ui5-page>
