import {Component, ElementRef, OnInit, AfterViewInit, ViewChild} from '@angular/core';

import {LoadingComponent} from '../../shared/dialogs/loading/loading.component';
import {NotificationComponent} from '../../shared/dialogs/notification/notification.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PosService} from '../../services/pos.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: [ './forms.component.scss' ],
})
export class FormsComponent implements OnInit, AfterViewInit {
  @ViewChild('name') nameEl: ElementRef;
  @ViewChild('phoneInput') phoneEl: ElementRef;
  formSupplier: FormGroup;
  countries = [
    { id: '', value: '' },
    { id: 1, value: 'Portugal' },
    { id: 2, value: 'Spain' },
    { id: 3, value: 'France' }
  ];
  checkbox;

  constructor(private fb: FormBuilder,
              private loading: LoadingComponent,
              private notification: NotificationComponent,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.formSupplier = this.fb.group({
      name: ['', Validators.required],
      street: [''],
      zipcode: [''],
      country: ['Portugal'],
      web: [''],
      phone: ['', Validators.required],
      email: [''],
      twitter: [''],
      sms: [''],
      mobile: [''],
      pager: [''],
      fax: [''],
      active: [true]
    });
  }

  ngAfterViewInit(): void { }

  submit(): void {
    console.log(this.formSupplier.value);
    const self = this;
    const controls = self.formSupplier.controls;
    const name = self.nameEl.nativeElement;
    const phone = self.phoneEl.nativeElement;
    name.setAttribute('value-state', '');
    phone.setAttribute('value-state', '');
    /** check form */
    if (self.formSupplier.invalid) {
      Object.keys(controls).forEach(controlName => {
        controls[controlName].markAsTouched()
        if (controls[controlName].invalid) {
          try {eval(controlName).setAttribute('value-state', 'Error')} catch (e) {console.log(e)}
        }
      });
      return;
    }
    self.loading.show();
    setTimeout( () => {
      self.loading.hide();
      self.formSupplier.reset();
      self.notification.show('Success','Submetido com sucesso');
    }, 1000);
  }

}
