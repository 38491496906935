import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';

import { AuthService } from '../../auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { getTheme, setTheme } from '@ui5/webcomponents-base/dist/config/Theme';
import { LoadingComponent } from '../../shared/dialogs/loading/loading.component';
import { NotificationComponent } from '../../shared/dialogs/notification/notification.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit  {

  @ViewChild('shellbar') shellbarEl: ElementRef;
  @ViewChild('popover') popoverEl: ElementRef;
  @ViewChild('language') languageEl: ElementRef;
  @ViewChild('connection') connectionEl: ElementRef;
  @ViewChild('wcNotification') notificationEl: ElementRef;
  @ViewChild('popoverLang') popoverLangEl: ElementRef;
  @ViewChild('menu') menuEl: ElementRef;
  @ViewChild('wcConnection') wcConnectionEl: ElementRef;

  isLoggedIn$: Observable<boolean>;
  user: any;

  constructor(private router: Router,
              private authService: AuthService,
              private translate: TranslateService,
              private loading: LoadingComponent,
              private notification: NotificationComponent) { }

  ngOnInit() {
    const login = localStorage.getItem('login');
    if (login === 'Y') {
      this.authService.setLogin();
    }
    this.isLoggedIn$ = this.authService.isLoggedIn;
    this.user = localStorage.getItem('username');
  }

  onLogout() {
    const self = this;
    this.loading.show();

    /** Fake Logout **/
    setTimeout(() => {
      self.popoverEl.nativeElement.close();
      self.authService.setLogout();
      localStorage.setItem('login', 'N');
      self.loading.hide();
      self.router.navigate(['/login'], {
        queryParams: {
          returnUrl: this.router.url
        }
      });
    }, 1000);
    /**  **/

    /*this.authService.logout().subscribe(data => {
      this.popoverEl.nativeElement.close();
      this.authService.setLogout();
      localStorage.setItem('login', 'N');
      this.router.navigate(['/login'], {
        queryParams: {
          returnUrl: this.router.url
        }
      });
    },error => {
      this.notification.show('Error',error['error']);
      console.log(error.error);
    },() => {
      this.loading.hide();
    });*/
  }

  ngAfterViewInit(): void {
    const self = this;
    if (self.shellbarEl) {
      self.shellbarEl.nativeElement.addEventListener('profile-click', event =>
        self.popoverEl.nativeElement.showAt(event.detail.targetRef));
      self.shellbarEl.nativeElement.addEventListener('notifications-click', event =>
        self.notificationEl.nativeElement.show());
      self.languageEl.nativeElement.addEventListener('click', event =>
          self.popoverLangEl.nativeElement.showAt(event.detail.targetRef));
      self.connectionEl.nativeElement.addEventListener('click', event =>
        self.wcConnectionEl.nativeElement.show());
      self.menuEl.nativeElement.addEventListener('click', event =>
        setTimeout(() => window.dispatchEvent(new Event('resize')),200));
      self.shellbarEl.nativeElement.addEventListener('menu-item-click', event => {
        if(event['detail']['item']) {
          const url = event['detail']['item'].classList[0];
          this.router.navigate([url.replace('menu-','/')]);
          const menu = (<HTMLInputElement>document.querySelector('.sidebar'));
          if(menu) {
            const menuItems = menu.querySelectorAll('ui5-side-navigation-item');
            if(menuItems) menuItems.forEach((item) => {
                if(item.classList[0] != url) item['selected'] = false;
            });
          }
        }
      });
      self.shellbarEl.nativeElement.addEventListener('product-switch-click', event => {
        const menu = (<HTMLInputElement>document.getElementById('menu'));
        if(menu) {
          menu.click();
        }
      });
      setTimeout(() => {
        // @ts-ignore
        const shadowShellEl = <HTMLInputElement>document.querySelector('ui5-shellbar').shadowRoot;
        const rightMenu = shadowShellEl.querySelector('ui5-button[title="Products"]');
        if(rightMenu) {
          //rightMenu.setAttribute('style', 'order:6 !important;visibility:visible !important;');
        }
      },500);
    }
  }

  toggleTheme(): void {
    const html = (<HTMLInputElement>document.documentElement);
    if (getTheme() === 'sap_fiori_3_dark') {
      setTheme('sap_fiori_3');
      localStorage.setItem('theme', 'sap_fiori_3');
      html.classList.add('theme-white');
    } else {
      setTheme('sap_fiori_3_dark');
      localStorage.setItem('theme', 'sap_fiori_3_dark');
      html.classList.remove('theme-white');
    }
  }

  changeLanguage(language: string): void {
    this.translate.use(language);
    localStorage.setItem('lang',language);
  }

}
