import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { of, Subject} from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../auth/auth.service';
import { LoadingComponent } from '../../shared/dialogs/loading/loading.component';

import { NotificationComponent } from '../../shared/dialogs/notification/notification.component';
import {WebAuthnService} from '../../services/web-authn.service';
import {ServerMockService} from '../../services/server-mock.service';
import {User} from '../../interfaces/user';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('dialogLogin') dialogLoginEl: ElementRef;
  @ViewChild('username') usernameEl: ElementRef;
  @ViewChild('password') passwordEl: ElementRef;
  private unsubscribe: Subject<any>;
  private formSubmitAttempt: boolean;
  loginForm: FormGroup;
  returnUrl: string;
  users: User[];
  email = 'a@a.com';
  password = 'aaa';
  useFingerprint = true;
  webAuthnAvailable = !!navigator.credentials && !!navigator.credentials.create;

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private translate: TranslateService,
              private authService: AuthService,
              private loading: LoadingComponent,
              private notification: NotificationComponent,
              private webAuthnService: WebAuthnService,
              private serverMockService: ServerMockService) {
    this.unsubscribe = new Subject();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'] || '/';
    });

    this.loginForm = this.fb.group({
      username: ['user', Validators.required],
      password: ['password', Validators.required],
      remember: true
    });
    this.authService.setLogout();
    localStorage.setItem('login', 'N');
  }

  confirmLogin(): void {
    this.dialogLoginEl.nativeElement.close();
    this.onSubmit(true);
  }

  closeDialog(): void {
    this.dialogLoginEl.nativeElement.close();
  }

  onSubmit(confirm, biometric = false): void {
    const self = this;
    const controls = self.loginForm.controls;
    const username = self.usernameEl.nativeElement;
    const passwd = self.passwordEl.nativeElement;
    username.setAttribute('value-state', '');
    passwd.setAttribute('value-state', '');
    self.loading.show();
    /** check form */
    if (self.loginForm.invalid && !biometric) {
      Object.keys(controls).forEach(controlName => {
        controls[controlName].markAsTouched()
        if (controls[controlName].invalid) {
          try {eval(controlName).setAttribute('value-state', 'Error')} catch (e) {}
        }
      });
      self.loading.hide();
      return;
    }
    const formData = {
      username: controls['username'].value,
      password: controls['password'].value
    };

    /** Fake Login **/
    setTimeout(() => {
      if(biometric) {
        self.authService.fakeLogin({userName: formData.username, password: 'password'});
      } else {
        self.authService.fakeLogin({userName: formData.username, password: formData.password});
      }

      self.loginForm.reset();
      self.loading.hide();
    }, 1000);
    /**  **/

    /*self.authService.login({userName: formData.username, password: formData.password}, confirm).pipe(
      tap(user => {
        if (user['Success'] == true) {
          self.authService.setLogin();
          localStorage.setItem('login', 'Y');
          localStorage.setItem('userid', user['Login']['User']);
          localStorage.setItem('username', user['Login']['UserName']);
          localStorage.setItem('user', user['Login']['DisplayName']);
          localStorage.setItem(environment.authTokenKey, user['Login']['Token']);
          self.loginForm.reset();
          self.router.navigate([this.returnUrl]);
        } else {
          if(user['MessageCode'] == 'LOGIN_NOTMATCH') {
            self.dialogLoginEl.nativeElement.open();
          } else {
            localStorage.setItem('login', 'N');
            self.loginForm.reset();
            self.notification.show('Error','Login inválido');
          }
        }
      }),
      catchError((err) => {
        self.notification.show('Error',err['Message']);
        return of();
      }),
      takeUntil(self.unsubscribe),
      finalize(() => {
        self.formSubmitAttempt = true;
        self.loading.hide();
      })
    ).subscribe();*/

  }

  removeUser(email: string) {
    this.serverMockService.removeUser(email);
    this.users = this.serverMockService.getUsers();
  }

  signup() {
    console.log('SIGNUP');

    // Save into the 'DB'
    const prevUser = this.serverMockService.getUser(this.email);
    if (prevUser) {
      alert('🚫 User already exists with this email address');
      return;
    }
    const user: User = this.serverMockService.addUser({ email: this.email, password: this.password, credentials: [] });
    this.users = this.serverMockService.getUsers();

    // Ask for WebAuthn Auth method
    if (this.webAuthnAvailable && this.useFingerprint) {
      this.webAuthnService.webAuthnSignup(user)
        .then((credential: PublicKeyCredential) => {
          console.log('credentials.create RESPONSE', credential);
          const valid = this.serverMockService.registerCredential(user, credential);
          this.users = this.serverMockService.getUsers();
        }).catch((error) => {
        console.log('credentials.create ERROR', error);
      });
    }
  }

  signin() {
    console.log('[signin]');
    const user = this.serverMockService.getUsers().find(u => u.email === this.email && u.password === this.password );
    if (user) {
      alert('✅ Congrats! Authentication went fine!');
    } else {
      alert('🚫 Sorry :( Invalid credentials!');
    }
  }

  webAuthSignin() {
    const user = this.serverMockService.getUser(this.email);
    this.webAuthnService.webAuthnSignin(user).then((response) => {
      // TODO: validate attestion
      //alert('✅ Congrats! Authentication went fine!');
      this.onSubmit(false, true);
      console.log('SUCCESSFULLY GOT AN ASSERTION!', response);
    })
      .catch((error) => {
        alert('🚫 Credencias Invalidas!');
        console.log('FAIL', error);
      });
  }

  fingerprintSignin() {
    const prevUser = this.serverMockService.getUser(this.email);
    if (prevUser) {
      this.webAuthSignin();
    } else {
      this.signup();
    }
  }
}
