<ui5-list id="todo-list" mode="MultiSelect" (selectionChange)="handleChangeSelection($event)">
  <ng-container *ngFor="let todo of todos">
      <ui5-li-custom
        [key]="todo.id"
        [id]="todo.id"
        [selected]="todo.done || undefined"
        [data-key]="todo.id"
        ng-class="{ 'hidden': todo.hidden }">
        <div class="li-content">
          <span class="li-content-text">{{todo.text}} - {{'todo.finishbefore' | translate}}: {{todo.deadline}}</span>
          <div>
            <ui5-button (click)="handleEdit(todo.id)" class="edit-btn">{{'general.edit' | translate}}</ui5-button>
            <ui5-button design="Negative" (click)="handleDeleteItem(todo.id)">{{'general.delete' | translate}}</ui5-button>
          </div>
        </div>
      </ui5-li-custom>
  </ng-container>
</ui5-list>
