<ui5-page id="page" class="page" background-design="Solid">
  <header class="detail-page-header">
    <div class="detail-page-header-bar">
      <ui5-title>{{'menu.events' | translate}}</ui5-title>
      <!--<ui5-button
        design="Transparent"
        icon="sap-icon://action"
        class="action-button"></ui5-button>-->
    </div>
  </header>
  <div class="details-page calendar">

    <div class="calendar-header" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-around center">
      <ui5-segmented-button>
        <ui5-segmented-button-item mwlCalendarPreviousView [(viewDate)]="viewDate" [view]="view">{{'events.previous' | translate}}</ui5-segmented-button-item>
        <ui5-segmented-button-item mwlCalendarToday [(viewDate)]="viewDate" pressed>{{'events.today' | translate}}</ui5-segmented-button-item>
        <ui5-segmented-button-item mwlCalendarNextView [(viewDate)]="viewDate" [view]="view">{{'events.next' | translate}}</ui5-segmented-button-item>
      </ui5-segmented-button>
      <h3 style="text-transform: capitalize;">{{ viewDate | calendarDate:(view + 'ViewTitle'): locale }}</h3>
      <ui5-segmented-button>
        <ui5-segmented-button-item (click)="setView(CalendarView.Month)" pressed>{{'events.month' | translate}}</ui5-segmented-button-item>
        <ui5-segmented-button-item (click)="setView(CalendarView.Week)">{{'events.week' | translate}}</ui5-segmented-button-item>
        <ui5-segmented-button-item (click)="setView(CalendarView.Day)">{{'events.day' | translate}}</ui5-segmented-button-item>
      </ui5-segmented-button>
    </div>

    <!--<div class="alert alert-info">
      Click on a month view day or a time on the week or day view to select it
    </div>-->

    <div [ngSwitch]="view">
      <mwl-calendar-month-view
        *ngSwitchCase="CalendarView.Month"
        [locale]="locale"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        [activeDayIsOpen]="activeDayIsOpen"
        (dayClicked)="dayClicked($event.day)"
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
      >
      </mwl-calendar-month-view>
      <mwl-calendar-week-view
        *ngSwitchCase="CalendarView.Week"
        [locale]="locale"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
      >
      </mwl-calendar-week-view>
      <mwl-calendar-day-view
        *ngSwitchCase="CalendarView.Day"
        [locale]="locale"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
      >
      </mwl-calendar-day-view>
    </div>
  </div>
</ui5-page>
