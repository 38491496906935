import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Pos } from '../../models/pos';
import { PosService } from '../../services/pos.service';
// import {POS} from '../mock-pos';

@Component({
  selector: 'app-hero-detail',
  templateUrl: './pos-detail.component.html',
  styleUrls: [ './pos-detail.component.scss' ]
})
export class PosDetailComponent implements OnInit {
  @ViewChild('DatePickerStart') dateStartEl: ElementRef;
  @ViewChild('DatePickerEnd') dateEndEl: ElementRef;
  pos: Pos;
  form: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private posService: PosService,
    private fb: FormBuilder,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.getHero();
    this.form = this.fb.group({
      dateStart: ['', Validators.required],
      dateEnd: ['', Validators.required]
    });
  }

  getHero(): void {
    const id = Number(this.route.snapshot.paramMap.get('id'));
    /*this.posService.getPOS(id)
      .subscribe(pos => this.pos = pos);*/
    this.posService.getPOSs().subscribe(data => {
      const poss = (data as any).data;
      this.pos = poss.find(h => h.id === id) as Pos;
    });
  }

  goBack(): void {
    this.location.back();
  }

  exportSAFT(): void {
    const controls = this.form.controls;
    const dateStart = this.dateStartEl.nativeElement;
    const dateEnd = this.dateEndEl.nativeElement;
    dateStart.setAttribute('value-state', '');
    dateEnd.setAttribute('value-state', '');
    /** check form */
    if (this.form.invalid) {
      Object.keys(controls).forEach(controlName => {
        controls[controlName].markAsTouched()
        if (controls[controlName].invalid) {
          try {eval(controlName).setAttribute('value-state', 'Error')} catch (e) {}
        }
      });
      return;
    }
    const formData = {
      dateStart: controls['dateStart'].value,
      dateEnd: controls['dateEnd'].value
    };
    const downloadLink = document.createElement('a');
    downloadLink.href = '/assets/data/SAFT-Example.xml';
    downloadLink.download = 'SAFT-Example-'+formData.dateStart+'-'+formData.dateStart+'.xml';
    downloadLink.click();
    this.form.reset();
  }
}
