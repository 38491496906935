import {Component, OnInit} from '@angular/core';

import { setTheme } from '@ui5/webcomponents-base/dist/config/Theme';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'Angular-UI5';

  constructor(private translate: TranslateService) {
    translate.addLangs(['en','pt']);
    translate.setDefaultLang('pt');
  }

  ngOnInit() {
    this.initTheme();
    if(localStorage.getItem('lang')) {
      this.translate.use(localStorage.getItem('lang'));
    }else {
      this.translate.use('pt');
    }
  }

  initTheme() {
    const theme = localStorage.getItem('theme');
    const html = (<HTMLInputElement>document.documentElement);
    if (theme) {
      setTheme(theme);
      if (theme === 'sap_fiori_3') {
        html.classList.add('theme-white');
      } else {
        html.classList.remove('theme-white');
      }
    } else {
      setTheme('sap_fiori_3');
      html.classList.add('theme-white');
      localStorage.setItem('theme', 'sap_fiori_3');
    }
  }
}
