import {Component, ElementRef, OnInit} from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  el: ElementRef;

  constructor(private _element: ElementRef) {
    this.el = _element;
  }

  ngOnInit(): void { }

  /*show(): void {
    console.log(this.el.nativeElement.parentElement);
    const loading = this.el.nativeElement.parentElement.querySelector('.loadingDialog');
    if(loading) { loading.open(); }
  }

  hide(): void {
    console.log(this.el.nativeElement.parentElement);
    const loading = this.el.nativeElement.parentElement.querySelector('.loadingDialog');
    if(loading) { loading.close(); }
  }*/

  show(): void {
    const loading = <HTMLInputElement>document.querySelector('#loadingDialog');
    if(loading) {
      //setTimeout(() => {
        // @ts-ignore
        loading.show();
      //},100);
    }
  }

  hide(): void {
    const loading = <HTMLInputElement>document.querySelector('#loadingDialog');
    if(loading) {
      //setTimeout(() => {
        // @ts-ignore
        loading.close();
      //},100);
    }
  }
}
