import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: [ './links.component.scss' ]
})
export class LinksComponent implements OnInit {
  year = '';

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    const today = new Date();
    const year = today.getFullYear();
    this.year = year.toString();
  }

}
