import {AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';

import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit {
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(!this.eRef.nativeElement.contains(event.target) && window.innerWidth <= 960) {
      if(!this.sidebarEl.nativeElement.collapsed && event.target.id != 'menu') {
        const menu = (<HTMLInputElement>document.getElementById('menu'));
        if(menu) menu.click();
      }
    }
  }
  @ViewChild('sidebar') sidebarEl: ElementRef;
  @ViewChild('menu') menuEl: ElementRef;
  isLoggedIn$: Observable<boolean>;

  constructor(private authService: AuthService,
              private breakpointObserver: BreakpointObserver,
              private router: Router,
              private eRef: ElementRef) { }

  ngOnInit(): void {
    const login = localStorage.getItem('login');
    if (login === 'Y') {
      this.authService.setLogin();
    }
    this.isLoggedIn$ = this.authService.isLoggedIn;
  }

  ngAfterViewInit(): void {
    const self = this;
    const menu = (<HTMLInputElement>document.getElementById('menu'));
    const page = (<HTMLInputElement>document.querySelector('.app-content'));
    if (menu) {
      menu.addEventListener('click', function(event) {
        self.toggleMenu();
        if (page) {
          page.classList.toggle('collapsed');
        }
      });
    }

    self.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      const url = event['url'];
      if (url) {
        const menuItem = this.sidebarEl.nativeElement.querySelector(url.replace('/','.menu-')
          .replace(/\/+/g,'')
          .replace(/[0-9]/g, '')
          .replace(/\?.*$/, ''));
        if (menuItem) menuItem['selected'] = true;
      }
      if(window.innerWidth <= 960) {
        //self.sidebarEl.nativeElement.collapsed = true;
        setTimeout(() => document.body.click(),100);
      }
    });

    self.breakpointObserver.observe([
      '(max-width: 960px)'
    ]).subscribe(result => {
      if (result.matches) {
        self.sidebarEl.nativeElement.collapsed = true;
        page.classList.add('collapsed');
      } else {
        // if necessary:
        self.sidebarEl.nativeElement.collapsed = false;
        page.classList.remove('collapsed');
      }
    });

  }

  toggleMenu(): void {
    this.sidebarEl.nativeElement.collapsed = !this.sidebarEl.nativeElement.collapsed;
  }

}
