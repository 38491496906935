import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { Pos } from '../models/pos';
import { POS } from '../mock-pos';
import { MessageService } from './message.service';

@Injectable({ providedIn: 'root' })
export class PosService {

  constructor(private messageService: MessageService, private httpClient: HttpClient) { }

  /*getPosMock(): Observable<Pos[]> {
    const pos = of(POS);
    this.messageService.add('PosService: fetched pos');
    return pos;
  }*/

  getPOSs(): Observable<Pos[]> {
    // this.messageService.add('PosService: fetched pos');
    return this.httpClient.get<Pos[]>('/assets/data/data.json');
  }

  getPOS(id: number): Observable<Pos> {
    const pos = POS.find(h => h.id === id) as Pos;
    // this.messageService.add(`PosService: fetched pos id=${id}`);
    return of(pos);
  }
}
