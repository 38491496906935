<ui5-page id="page" background-design="List">
  <header class="detail-page-header">
    <div class="detail-page-header-bar">
      <ui5-title>{{'menu.usefullinks' | translate}}</ui5-title>
      <ui5-button
        design="Transparent"
        icon="synchronize"
        class="action-button"></ui5-button>
    </div>
  </header>
  <div class="details-page">
    <h3 style="font-weight: 400">Credits</h3>
    <p>Concept and production:</p>
    <p>This App was created by Newoxygen using&nbsp;open-source software.</p>
    <p>
      <span class="newoxygen" >Copyright © <span>{{year}}</span> -&nbsp;
        <a href="https://newoxygen.com" target="_blank" class="link">
          <span style="font-size: 16px;font-weight: bolder; color: #0094da;">new</span>
          <span style="font-size: 16px;">oxygen</span>
        </a>
      </span>
    </p>
  </div>
</ui5-page>


