<ui5-side-navigation #sidebar class="sidebar" [hidden]="!(isLoggedIn$ | async)">
  <ui5-side-navigation-item text="{{ 'menu.home' | translate }} " icon="bar-chart"
                            routerLink="/dashboard" class="menu-dashboard"></ui5-side-navigation-item>
  <ui5-side-navigation-item text="{{ 'menu.pos' | translate }} " icon="sys-monitor"
                            routerLink="/pos" class="menu-pos"></ui5-side-navigation-item>
  <ui5-side-navigation-item text="{{ 'menu.events' | translate }} "
                            routerLink="/events" class="menu-events" icon="calendar">
    <!--<ui5-side-navigation-sub-item text="Local"></ui5-side-navigation-sub-item>
    <ui5-side-navigation-sub-item text="Others"></ui5-side-navigation-sub-item>-->
  </ui5-side-navigation-item>
  <ui5-side-navigation-item text="Taskboard " icon="activities"
                            routerLink="/taskboard" class="menu-taskboard"></ui5-side-navigation-item>
  <ui5-side-navigation-item text="{{ 'menu.examples' | translate }} " expanded icon="customer-view" >
    <ui5-side-navigation-sub-item text="Reactive Form "
                                  routerLink="/forms" class="menu-forms"></ui5-side-navigation-sub-item>
    <ui5-side-navigation-sub-item text="{{ 'menu.todolist' | translate }} "
                                  routerLink="/todo" class="menu-todo"></ui5-side-navigation-sub-item>
    <!--<ui5-side-navigation-sub-item text="From Other Teams"></ui5-side-navigation-sub-item>-->
  </ui5-side-navigation-item>
  <ui5-side-navigation-item slot="fixedItems" text="{{ 'menu.history' | translate }} " icon="history"
                            routerLink="/history" class="menu-history"></ui5-side-navigation-item>
  <ui5-side-navigation-item slot="fixedItems" text="{{ 'menu.about' | translate }} " icon="message-information"
                            routerLink="/links" class="menu-links"></ui5-side-navigation-item>
</ui5-side-navigation>
